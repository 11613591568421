import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Boilerplate from '../components/boilerplate'

const IndexPage = () => (
  <Layout>
    <SEO title="Datenschutz" keywords={['creopharm', 'pharma', 'co-packing']} />

    <Hero />

    <div className="container medium-padding">
      <h2>Datenschutz</h2>

      <p>
        Im Folgenden möchten wir Ihnen einen Überblick über die Erhebung und
        Verarbeitung Ihrer personenbezogenen Daten durch uns im Rahmen einer
        Zusammenarbeit mit Ihnen und Ihre dies betreffenden Rechte aus dem
        Datenschutzrecht geben. Nachstehend finden Sie Informationen, welche
        Daten wir im Zusammenhang mit einer Geschäftsbeziehung mit Ihnen erheben
        und in welcher Weise diese verarbeitet und genutzt werden. Die
        Informationen erteilen wir Ihnen nach Maßgabe der Art. 13,14
        EU-Datenschutz-Grundverordnung (DS-GVO).
      </p>
      <p>
        <strong>
          1. Verantwortliche Stelle für die Erhebung und Verarbeitung Ihrer
          Daten
        </strong>
      </p>

      <p>
        Creopharm GmbH
        <br />
        Bielefelder Str. 21a
        <br />
        49124 Georgsmarienhütte
        <br />
        Tel.: +49 5401 8814-0
        <br />
        Fax: +49 5401 8814-100
        <br />
        Email:{' '}
        <a href="mailto:datenschutz@creopharm.de">datenschutz@creopharm.de</a>
        <br />
        Website: <a href="http://www.creopharm.de/">www.creopharm.de</a>
      </p>

      <p>
        <strong>2. Datenschutzbeauftragter</strong>
      </p>

      <p>
        Die Creopharm GmbH hat einen Beauftragten für den Datenschutz bestellt.
        Sie erreichen unseren betrieblichen Datenschutzbeauftragten unter der
        E-Mail:
        <a href="mailto:datenschutz@creopharm.de">datenschutz@creopharm.de</a>
        oder postalisch unter der oben angegebenen Adresse.
      </p>

      <p>
        <strong>3. Welche Daten nutzt die Creopharm GmbH</strong>
      </p>

      <p>
        Die Creopharm GmbH verarbeitet personenbezogene Daten von und Kunden und
        Ansprechpartnern bei Kunden, die sie von diesen im Rahmen einer
        Geschäftsbeziehung oder der Anbahnung einer Geschäftsbeziehung erhält.
      </p>

      <p>
        Darüber hinaus verarbeiten wir personenbezogene Daten, die wir unter
        Beachtung datenschutzrechtlicher Vorgaben aus öffentlichen
        Verzeichnissen entnommen haben. z.B. Handelsregister,
        Schuldnerverzeichnisse, Internet oder die uns durch Auskunfteien zur
        Verfügung gestellt werden.
      </p>

      <p>
        Dabei handelt es sich um folgende Kategorien von Daten: Stammdaten zur
        Person (Name, Berufs,-Branchen- oder Geschäftsbezeichnung, Geburtstag);
        Kontaktdaten (Anschrift, Telefonnummer, email-Adresse), Zahlungsdaten
        bei bargeldloser Zahlung (Bankdaten, EC-Karten-Daten), Daten zur
        Legitimation (Login-Dateien, Ausweisdaten, Unterschriften), Daten zur
        Geschäftshistorie ( Angebote, Auftragsbestätigungen, Umsätze,
        Lieferanschriften), Daten zur finanziellen Leistungsfähigkeit (
        Bonitätsauskünfte) und Werbedaten (z.B. Werbeeinwilligungen oder
        Werbeverbote)
      </p>

      <p>
        <strong>
          4. Zu welchen Zwecken verarbeitet die Creopharm GmbH Ihre Daten und
          auf welcher Rechtsgrundlage?
        </strong>
      </p>

      <p>
        Die Creopharm GmbH verarbeitet Ihre personenbezogenen Daten unter
        Beachtung der Anforderungen der Europäischen Datenschutz-Grundverordnung
        (DS-GVO) sowie der nationalen Vorschriften zur Erreichung folgender
        Zwecke:
      </p>

      <p>
        Wir verarbeiten Ihre personenbezogenen Daten zum Zweck der
        Vertragserfüllung einschließlich bargeldloser Zahlungsabwicklung, zur
        Bearbeitung von Reklamationen und ggf. zur Schadensabwicklung. Darüber
        hinaus verarbeiten wir Ihre Daten vor Vertragsabschluss soweit Sie uns
        um Informationen zu unseren Produkten und Dienstleistungen ersuchen. Die
        Verarbeitung erfolgt hierauf auf Grundlage von Art 6 Abs. 1 Satz 1 lit.
        b) DS-GVO
      </p>

      <p>
        Wir verarbeiten Ihre Daten ferner, soweit Sie uns hierzu eine
        Einwilligung erteilt haben. Die Verarbeitung erfolgt in diesem Fall auf
        Grund von Art.6 Abs. 1 lit a.) DS-GVO. Anlässlich der Einholung einer
        Einwilligung informieren wir Sie über den konkreten Zweck der
        beabsichtigten Verarbeitung. Ein uns gegenüber erteilte Einwilligung
        können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Dies gilt
        auch für Einwilligungen, die Sie vor dem 25.Mai 2018 erteilt haben.{' '}
      </p>

      <p>
        Schließlich verarbeiten wir Daten, soweit wir hieran ein berechtigtes
        Interesse haben, sofern nicht Ihre Interessen oder Grundrechte, die den
        Schutz Ihrer personenbezogener Daten erfordern, überwiegen. Die
        Verarbeitung erfolgt in diesem Fall auf Grundlage von Art. 6 Abs. 1 Satz
        1 lit. f) DS-GVO. Zu diesen Verarbeitungen gehören die Zusendung von
        Informationen und Angeboten der Creopharm GmbH, Nachfragen zur
        Zufriedenheit und die Markt- und Meinungsforschung, soweit Sie diesen
        Verarbeitungen nicht widersprochen haben. Darüber hinaus verarbeiten wir
        auf der Grundlage eines berechtigten Interesses personenbezogene Daten
        zur Beurteilung der Bonität eines Kunden zur Durchsetzung von
        Zahlungsansprüchen auch unter Einschaltung von Inkassodienstleistern und
        Rechtsbeiständen.
      </p>

      <p>
        <strong>
          5. An wen werden Ihre Daten von der Creopharm GmbH weitergegeben?
        </strong>
      </p>

      <p>
        Innerhalb der Creopharm GmbH erhalten Ihre Daten diejenigen
        Fachabteilungen, die diese zur Erfüllung des Vertrages oder Durchführung
        vorvertraglicher Maßnahmen benötigen (z.B. Finanzbuchhaltung). Daneben
        erhalten diejenigen Fachabteilungen die Ihre Daten auf Grundlage eines
        berechtigten Interesses verarbeiten, beispielsweise das Marketing zur
        werblichen Ansprache.
      </p>

      <p>
        Soweit dies erforderlich ist, geben wir Daten auch uns unterstützende
        Dienstleister weiter (z.B. Postdienstleister, Finanzdienstleister,
        Logistikunternehmen, IT-Dienstleister, Inkassodienstleister und
        Rechtsbeistände.
      </p>

      <p>
        Unser Unternehmen prüft regelmäßig bei Vertragsabschluss und in
        bestimmten Fällen, in denen ein berechtigtes Interesse vorliegt, die
        Bonität unserer Geschäftspartner. Dazu arbeiten wir mit der Creditreform
        Osnabrück/ Nordhorn Unger KG (Parkstraße 32, 49080 Osnabrück) zusammen,
        von der wir die dazu benötigten Daten erhalten. Zu diesem Zweck
        übermitteln wir den Namen unserer Geschäftspartner und die dazugehörigen
        Kontaktdaten an die Creditreform. Weitere Informationen zur
        Datenverarbeitung erhalten Sie in dem ausführlichen Merkblatt
        Creditreform-Information gem. Art. 14 EU-DSGVO
      </p>

      <p>
        Eine Weitergabe Ihrer Daten an Dritte außerhalb der Creopharm GmbH
        erfolgt an Finanzbehörden zur Erfüllung von Melde- und Nachweispflichten
        und an Strafverfolgungsbehörden, soweit hierzu eine rechtliche
        Verpflichtung besteht.
      </p>

      <p>
        <strong>
          6. Werden Ihre Daten durch die Creopharm GmbH an eine internationale
          Organisation oder in ein Drittland übermittelt?
        </strong>
      </p>

      <p>
        Unsere Website benutzt Google Analytics und Google Remarketing. Die
        Dienste werden von Google Inc. (Google) angeboten.
      </p>

      <p>
        Die Erfassung durch Google Analytics und Google Remarketing können Sie
        verhindern, indem Sie auf den untenstehenden Link klicke. Dadurch wird
        in Ihrem Browser ein „Opt-Out-Cookie“ installiert, sodass zukünftig
        keine Erfassung Ihrer Daten beim Besuch dieser Website stattfindet:{' '}
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
          tools.google.com/dlpage/gaoptout?hl=de
        </a>
        .
      </p>

      <p>
        Google Analytics und Google Remarketing sind Dienstleistungen von
        Google. Von Google werden sog. Cookies, Textdateien, verwendet, die auf
        Ihrem Computer gespeichert werden. Hierdurch wird die Nutzungsanalyse
        dieser Website durch Sie ermöglicht. Durch den cookie werden
        Informationen über Ihre Benutzung unserer Website durch Sie ermöglicht.
        Durch das Cookie werden Informationen über Ihre Benutzung unserer
        Website erzeugt und diese (einschl. Ihrer IP-Adresse) an einen Server
        von Google in den USA übertagen und gespeichert. Die Nutzung der
        Funktion „anonymizelP“ für IP Adresse garantiert innerhalbe der
        Europäischen Union oder Mitgliedsstaaten des europäischen
        Wirtschaftsraums die Kürzung der IP-Adresse um das letzte Oktett,
        wodurch eine eindeutige Zuordnung Ihrer IP-Adresse somit nicht mehr
        möglich ist. Google beachtet die Datenschutzbestimmungen des
        „US-Safe-Harbor“- Abkommens uns beim „Safe Harbor“- Programm des
        US-Handelsministeriums registriert. Die Informationen werden von Google
        genutzt, um Ihre Nutzung der Website auszuwerten. Es werden Reports über
        die Websiteaktivitäten für den Websitebetreiber erstellt und weitere im
        Zusammenhang mit der Websitenutzung und der Internetnutzung verbundene
        Dienstleistungen ermöglicht. Die Informationen werden ggf. von Google an
        Dritte übertragen, sofern dies gesetzlich vorgeschrieben wird oder
        Dritte diese Daten im Auftrag von Google verarbeiten. Es werden Anzeigen
        auf Websites im Internet durch Drittanbieter und Google geschaltet.
        Google und Drittanbieter werden die Daten nutzen, um Werbung auf
        Webseiten Dritter zu schalten.
      </p>

      <p>
        Die IP-Adresse wird in keinem Fall mit anderen Daten von Google in
        Verbindung gebracht. Die Datenerhebung und – Speicherung kann jederzeit
        mit Wirkung für die Zukunft widersprochen werden. Sie können die
        Verwendung und Speicherung von Cookies durch Google durch entsprechende
        Einstellung Ihrer Browser- Software deaktivieren, indem Sie die Seite{' '}
        <a href="http://www.google.de/privacy/ads">www.google.de/privacy/ads</a>{' '}
        aufrufen und den „Opt-Out“ Button betätigen.
      </p>

      <p>
        Alternativ können Sie die Verwendung von Cookies durch Drittanbietern
        deaktivieren, indem sie die Deaktivierungsseite der
        Netzwerkewerbeinitiative unter{' '}
        <a href="http://www.networkadvertising.org/managing/opt_out.asp">
          www.Networkadvertising.org/managing/opt_out.asp{' '}
        </a>
        aufrufen. Weitere Hinweise zu den Bestimmungen von Google finden Sie
        unter{' '}
        <a href="http://www.google.com/intl/de/privacy">
          www.google.com/intl/de/privacy
        </a>
        .
      </p>

      <p>
        Sollten Sie der Verwendung von Cookies widersprechen, weisen wir Sie
        darauf hin, dass Sie ggf. nicht alle Funktionen dieser Webseite
        vollumfänglich nutzen können. (Auszug Datenschutzrichtlinien Google
        Analytics).
      </p>

      <p>
        Diese Website nutzt ferner das Google Conversion Tracking. Dabei wird
        von Google AdWords ein Cookie auf Ihrem Rechner gesetzt, sofern Sie über
        eine Google-Anzeige auf unsere Webseite gelangt sind. Diese Cookies
        verlieren nach 30 Tagen Ihre Gültigkeit und dienen nicht der
        persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der
        Website des AdWords-Kunden und das Cookie ist noch nicht abgelaufen,
        können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige
        geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder
        AdWords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über
        die Webseiten von AdWords-Kunden nachverfolgt werden.
      </p>

      <p>
        Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie
        auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per
        Browser-Einstellung, die das automatischen Setzen von Cookies generell
        deaktiviert. Sie können Cookies für Conversion-Tracking auch
        deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von
        der Domain{' '}
        <a href="http://www.googleadservice.com/">www.googleadservice.com</a>{' '}
        blockiert werden. Googles Datenschutzbelehrung zum Conversion-Tracking
        finden Sie unter
      </p>

      <p>
        <a href="http://support.google.com/adwords/answer/1722022">
          Support.google.com/adwords/answer/1722022
        </a>
      </p>

      <p>
        <strong>Einbindung Google Maps</strong>
      </p>

      <p>
        Auf unserer Website wird Google Maps eingebunden. Im Moment des Aufrufs
        der Seite, in der Google Maps eingebunden ist, werden an Google
        Informationen über Ihren Zugriff übermittelt. Insbesondere erhält Google
        hierbei Ihre IP-Adresse, die Kennung des von Ihnen verwendeten
        Webbrowsers sowie die Angabe, von welcher Seite aus der Aufruf erfolgte.
      </p>

      <p>
        Weitere Informationen erhalten Sie bei Google unter{' '}
        <a href="http://www.google.de/intl/de/policies/">
          www.google.de/intl/de/policies/
        </a>
      </p>

      <p>
        <strong>
          7. Wie lange werden Ihre Daten durch die Creopharm GmbH gespeichert?
        </strong>
      </p>

      <p>
        Wir speichern Ihre personenbezogenen Daten für die Dauer der
        Vertragserfüllung. Wenn und soweit Ihre Daten steuerrechtlichen,
        handelsrechtlichen oder sonstige gesetzlichen Aufbewahrungspflichten
        unterliegen, speichern wir dies Daten bis zum Ablauf der vorbenannten
        Fristen. Diese Fristen betragen beispielsweise nach steuerrechtlichen
        Vorschriften 6 Jahre und nach handelsrechtlichen Vorschriften 10 Jahre.
      </p>

      <p>
        <strong>
          8. Informationen zu Datenschutzrechen der Kunden / Aufsichtsbehörde
        </strong>
      </p>

      <p>
        Jeder Kunde hat das Recht auf Auskunft über seine bei der Creopharm GmbH
        gespeicherten Daten und die dies betreffenden Verarbeitungen nach Art.15
        DS-GVO, das Recht auf Berichtigung seiner Daten nach Art. 16 DS-GVO, das
        Recht auf Löschung seiner Daten nach Vertragserfüllung nach At. 17
        DS-GVO, soweit dieser keine Aufbewahrungspflichten entgegenstehen, das
        Recht auf Einschränkung der Verarbeitung nach Art. 18 DS-GVO, das Recht
        auf Widerspruch nach Art.21 DS-GVO sowie das Recht auf
        Datenübertragbarkeit nach Art. 20 DS-GVO.
      </p>

      <p>
        Soweit Sie der Creopharm GmbH eine Einwilligung zu einer
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung
        bis zum Widerruf erfolgten Verarbeitung berührt wird.
      </p>

      <p>
        Bei Fragen und Auskünften zum Schutz Ihrer personenbezogenen Daten und
        zur Geltendmachung der vorstehenden Rechte werden Sie sich bitte an
        unseren Datenschutzbeauftragten unter dessen angegebenen Kontaktdaten.
      </p>

      <p>
        <strong>
          9. Information über das Bestehen eines Widerspruchsrecht nach Art.21
          DS-GVO
        </strong>
      </p>

      <p>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, der Verarbeitung Ihrer Daten zu widersprechen, die
        zur Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
        Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt (Art. 6
        Abs. 1 Sat 1 lit. e), welche der Creopharm GmbH übertragen wurde. Dies
        gilt auch für ein auf die vorstehende Bestimmung gestütztes Profiling.
        Wiedersprechen Sie der Verarbeitung, werden wir Ihre Daten zu diesen
        Zwecken nicht mehr verarbeiten, es sei denn, wir können zwingende
        schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
        Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
        dient der Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen.
      </p>

      <p>
        Wir verarbeiten Ihre Daten zur werblichen Ansprache und führen hierzu
        vorab Verarbeitungen durch, um unsere Marketingmaßnahmen auf Ihre
        Interessen auszurichten (Profiling). Diese Verarbeitung zum Zwecke der
        Direktwerbung erfolgt auf Grundlage eines überwiegenden berechtigten
        Interesses der Creopharm GmbH.
      </p>

      <p>
        Sie können der Verarbeitung Ihrer personenbezogenen Daten zum Zwecke der
        Direktwerbung jederzeit widersprechen. Dies gilt auch für das Profiling,
        soweit es mit der werblichen Ansprache in Verbindung steht.
        Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
        werden Ihre Daten nicht mehr für diese Zwecke verarbeitet.
      </p>

      <p>
        Ihr Widerspruch kann ohne Einhaltung einer besonderen Form erfolgen an:
      </p>

      <p>
        Creopharm GmbH, Bielefelder Str.21a, 49124 Georgsmarienhütte Tel. 05401
        8814-0 email:{' '}
        <a href="mailto:datenschutz@creopharm.de">datenschutz@creopharm.de</a>
      </p>

      <p>
        <strong>
          10. Müssen Kunden und Ansprechpartner bei Kunden der Creopharm GmbH
          personenbezogene Daten bereitstellen?
        </strong>
      </p>

      <p>
        Zur Vertragsanbahnung, für den Vertragsschluss und schließlich für die
        Erfüllung eines Vertrags mit der Creopharm GmbH ist es erforderlich,
        dass die unter Ziff. 3 genannten Daten des Kunden erhoben und
        verarbeitet werden. Ohne diese Daten sind wir nicht in der Lage, eine
        Geschäftsbeziehung mit dem Kunden einzugehen oder vertraglichen
        Leistungen im Rahmen einer bestehenden Geschäftsbeziehung zu erbringen.
      </p>

      <p>Stand 01.01.2019</p>
    </div>

    <Boilerplate lang="de" />
  </Layout>
)

export default IndexPage
